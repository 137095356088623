import React from "react"
import { TextField } from "@mui/material"

import * as css from "./style.module.scss"

const OnYourMind = () => {
    return <div className={css.graySession}>
        <div className={css.yourMindTitle}>Something on your mind?</div>
        <div className={css.sections}>
            <div className={css.section}>
                <div className={css.sectionTitle}>Future of work on your mind?</div>
                <div className={css.sectionText}>
                    Do you share our vision and values and want to explore how to get involved with us or any of the awesome companies above.
                </div>
                <a href="mailto:contact@wayfinders.ae">
                    <div className={css.highlightedText}>Say hi.</div>
                </a>
            </div>
            <div className={css.section}>
                <div className={css.sectionTitle}>Building something incredible?</div>
                <div className={css.sectionText}>
                    If you are passionate about changing how the world works and are developing technology,  solutions or services, we’d love to see it!
                </div>
                <a href="mailto:contact@wayfinders.ae">
                    <div className={css.highlightedText}>Get in touch.</div>
                </a>
            </div>
        </div>
        <div className={css.joinSection}>
            <div className={css.joinSectionSmallText}>Join our mailing list</div>
            <div className={css.joinSectionText}>Get the best updates on the Future of Work.</div>
            <div className={css.joinInputsContainer}>
                <TextField placeholder="Email Address" className={css.joinEmailInput} />
                <button onClick={() => { }} color="primary" className={css.joinSubmitButton}>Submit</button>
            </div>
        </div>
    </div>
}

export default OnYourMind;