// extracted by mini-css-extract-plugin
export var buttomText = "get_ready-module--buttom-text--e1qIz";
export var card = "get_ready-module--card--pL3iI";
export var cardImage = "get_ready-module--card-image--+OdI8";
export var cardRow = "get_ready-module--card-row--fnwzh";
export var cardText = "get_ready-module--card-text--TeMo3";
export var content = "get_ready-module--content--b7bLD";
export var contentBody = "get_ready-module--content-body--teiXp";
export var readyBenchmark = "get_ready-module--readyBenchmark--shFxJ";
export var readyHotdesk = "get_ready-module--readyHotdesk--8khlP";
export var readyPeaplePartners = "get_ready-module--readyPeaplePartners--y0ast";
export var readyRecruitment = "get_ready-module--readyRecruitment--CVGNZ";
export var readyRolebot = "get_ready-module--readyRolebot--1Z0AA";
export var readySGP = "get_ready-module--readySGP--p11L5";
export var readyText = "get_ready-module--ready-text--7E2rx";