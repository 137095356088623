import React from "react";
import ButtonBase from '@mui/material/ButtonBase';
import readySGPImage from "./../../images/ready_sgp.png";
import readyPeaplePartnersImage from "./../../images/ready_peoples_partner.png";
import readyBenchmarkImage from "./../../images/ready_benchmark.png";
import readyHotdeskImage from "./../../images/ready_hotdesk.png";
import readyMoreToFollowImage from "./../../images/ready_moretofollow.png";
import readyRecruitmentImage from "./../../images/ready_recruitment.png";
import readyRolebotImage from "./../../images/ready_rolebot.png";
import readyMyOffice from "./../../images/ready_my_office.png";
import readyDiverseResoursing from "./../../images/ready_diverse_resourcing.png";

import * as css from "./get_ready.module.scss";

const GetReady = () => {

    return (
        <>
            <div className={css.content}>
                <div className={css.contentBody}>
                    <div className={css.readyText}>Get Ready</div>
                    <div className={css.buttomText}>To accomplish better, faster by preparing for the future of work</div>
                    <div>
                        <div className={css.cardRow}>
                            <ButtonBase className={css.card} component="a" href="https://www.sgp.technology/" target="_blank">
                                <div className={`${css.cardText} ${css.readySGP}`}>
                                    <span>
                                        Building the
                                        <br />
                                        biggest
                                        <br />
                                        community of
                                        <br />
                                        tech-talent
                                    </span>
                                </div>
                                <img src={readySGPImage} className={css.cardImage} alt="SPG" />
                            </ButtonBase>
                            <ButtonBase className={css.card} component="a" href="https://www.peoplepartners.me/" target="_blank">
                                <div className={`${css.cardText} ${css.readyPeaplePartners}`}>
                                    <span>
                                        Onboarding
                                        <br />
                                        people globally,
                                        <br />
                                        simplified
                                    </span>
                                </div>
                                <img src={readyPeaplePartnersImage} className={css.cardImage} alt="People Partners" />
                            </ButtonBase>
                            <ButtonBase className={css.card}
                                component="a" href="https://www.benchmark.ws/" target="_blank">
                                <div className={`${css.cardText} ${css.readyBenchmark}`}>
                                    <span>
                                        Financial
                                        <br />
                                        Wellness for
                                        <br />
                                        ALL Employees.

                                    </span>
                                </div>
                                <img src={readyBenchmarkImage} className={css.cardImage} alt="Benchmark" />
                            </ButtonBase>
                        </div>
                        <div className={css.cardRow}>
                            <ButtonBase className={css.card} component="a" href="https://www.hotdesk.io/" target="_blank">
                                <div className={`${css.cardText} ${css.readyHotdesk}`}>
                                    <span>
                                        The future of
                                        <br />
                                        workspaces.
                                    </span>
                                </div>
                                <img src={readyHotdeskImage} className={css.cardImage} alt="Hotdesk" />
                            </ButtonBase>
                            <ButtonBase className={css.card} component="a" href="https://recruitmententrepreneur.com/" target="_blank">
                                <div className={`${css.cardText} ${css.readyRecruitment}`}>
                                    <span>
                                        The number one
                                        <br />
                                        investor to build
                                        <br />
                                        and exit
                                        <br />
                                        recruitment businesses
                                    </span>
                                </div>
                                <img src={readyRecruitmentImage} className={css.cardImage} alt="Recruitment" />
                            </ButtonBase>
                            <ButtonBase className={css.card} href="https://www.rolebot.io/" component="a" target="_blank" >
                                <div className={`${css.cardText} ${css.readyRolebot}`}>
                                    <span>
                                        World’s 1st
                                        <br />
                                        AI-powered
                                        <br />
                                        Sourcing Tool
                                        <br />
                                        For Passive Talent
                                    </span>
                                </div>
                                <img src={readyRolebotImage} className={css.cardImage} alt="More To Follow" />
                            </ButtonBase>

                        </div>
                        <div className={css.cardRow}>
                            <ButtonBase className={css.card} component="a" href="https://www.myoffice.ae/" target="_blank">
                                <div className={`${css.cardText} ${css.readyHotdesk}`}>
                                    <span>
                                        Dubai's Leading
                                        <br />
                                        Serviced Office
                                        <br />
                                        & Coworking Space
                                    </span>
                                </div>
                                <img src={readyMyOffice} className={css.cardImage} alt="My Office" />
                            </ButtonBase>
                            <ButtonBase className={css.card} component="a" href="https://www.diverse-resourcing.com/" target="_blank">
                                <div className={`${css.cardText} ${css.readyRecruitment}`}>
                                    <span>
                                        Delivering
                                        <br />
                                        A Diverse Approach
                                        <br />
                                        to
                                        <br /> Energy Recruitment
                                    </span>
                                </div>
                                <img src={readyDiverseResoursing} className={css.cardImage} alt="Diverse Resourcing" />
                            </ButtonBase>
                            <ButtonBase className={css.card} component="div" >
                                <div className={`${css.cardText} ${css.readyRolebot}`}>
                                    <span>
                                        + More to follow
                                    </span>
                                </div>
                                <img src={readyMoreToFollowImage} className={css.cardImage} alt="More To Follow" />
                            </ButtonBase>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GetReady;