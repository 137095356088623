// extracted by mini-css-extract-plugin
export var footer = "style-module--footer--UDgL6";
export var footerCopyright = "style-module--footer-copyright--vAU-j";
export var footerCopyrightMobile = "style-module--footer-copyright-mobile--wKSEU";
export var footerLogo = "style-module--footer-logo--IfLRO";
export var footerMenu = "style-module--footer-menu--0lQ5n";
export var footerMenuItem = "style-module--footer-menu-item--Uj8SW";
export var footerMenus = "style-module--footer-menus--M+5ir";
export var footerOuter = "style-module--footer-outer--OjwSq";
export var footerSections = "style-module--footer-sections--n0dpQ";
export var footerSlogan = "style-module--footer-slogan--2D8ZM";
export var letsChat = "style-module--lets-chat--NpV7R";
export var socialMedia = "style-module--social-media--Njx4y";