import * as React from "react"

import { Helmet } from "react-helmet"

import Hero from "../sections/hero"
import GetReady from "../sections/get_ready"
import SomethingOnYourMind from "../sections/something_on_your_mind"
import Footer from "../sections/footer"


const IndexPage = () => {



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wayfinders</title>
      </Helmet>
      <Hero />
      <GetReady />
      <SomethingOnYourMind />
      <Footer />
    </>


  )
}

export default IndexPage
