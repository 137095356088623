import React from "react"
import * as css from "./hero.module.scss"

const Hero = () => {
    return <>
        <div className={css.header}>
            <div className={css.headerContent}>
                <svg className={css.logo} viewBox="0 0 336 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2_431)">
                        <path d="M6.52971 24.5852C5.02388 21.8049 2.86508 18.376 0 18.376V17.9523H20.2954V18.376C16.7773 18.376 16.7773 20.7326 18.5763 24.4395L24.1732 35.9179L28.3309 26.6505L27.3981 24.5852C25.9589 21.5931 24.533 18.376 22.0677 18.376V17.9523H41.8834V18.376C38.2987 18.376 37.8856 20.812 39.3781 24.4395L43.6824 35.1236L45.6946 30.3575C46.9073 27.4316 48.1999 24.2277 48.1999 21.8711C48.1999 19.8058 47.1871 18.376 44.402 18.376V17.9523H57.3815V18.376C55.9547 18.3805 54.5529 18.7478 53.3096 19.4431C52.0662 20.1383 51.0228 21.1383 50.2787 22.3477C48.6166 24.9734 47.245 27.7699 46.1877 30.6884L38.8051 47.8994H38.5119L28.7574 27.4316L19.216 47.8861H18.9361L6.52971 24.5852Z" fill="#1D1D1B" />
                        <path d="M74.732 43.3186V33.098C73.6144 32.7662 72.4594 32.5749 71.2939 32.5287C67.5627 32.5287 65.9636 35.0971 65.9636 39.3601C65.9636 42.8553 67.0296 45.9797 70.1212 45.9797C71.0464 45.9178 71.9438 45.6397 72.7404 45.1681C73.537 44.6964 74.2102 44.0446 74.7054 43.2657M54.29 39.9691C54.29 33.9188 59.9669 31.6417 66.9097 31.6417C69.5448 31.6428 72.1705 31.9539 74.732 32.5684V25.1545C74.732 21.0901 73.8792 17.5949 69.2151 17.5949C68.0161 17.5767 66.8312 17.8537 65.7663 18.4012C64.7013 18.9487 63.7893 19.7497 63.1118 20.7326C64.0576 21.2028 64.8535 21.9245 65.4111 22.8174C65.9687 23.7102 66.266 24.7392 66.27 25.79C66.2579 26.4877 66.1071 27.1762 65.8263 27.8158C65.5455 28.4555 65.1402 29.0337 64.6337 29.5172C64.1272 30.0007 63.5295 30.38 62.875 30.6333C62.2204 30.8865 61.522 31.0088 60.8197 30.993C60.1282 30.9915 59.444 30.8523 58.8076 30.5835C58.1712 30.3146 57.5955 29.9217 57.1146 29.428C56.6338 28.9342 56.2575 28.3496 56.0081 27.7088C55.7587 27.068 55.6412 26.384 55.6626 25.6973C55.6626 20.9974 61.3928 17.2904 69.8547 17.2904C77.3839 17.2904 86.139 20.2825 86.139 28.835V43.9674C86.139 45.2913 86.8586 45.9665 88.0046 45.9665C89.8036 45.9665 91.4427 44.1792 92.6021 42.4713L92.9486 42.6832C90.9497 45.5296 88.511 48.3098 83.4872 48.3098C79.0363 48.3098 76.4511 46.0989 75.385 43.3186C74.1929 44.9732 72.6021 46.3043 70.7583 47.1902C68.9145 48.076 66.8765 48.4883 64.8308 48.3893C58.5943 48.3893 54.29 45.2516 54.29 39.9691Z" fill="#1D1D1B" />
                        <path d="M87.6447 56.7167C87.6447 50.587 100.624 55.8694 103.636 49.4484L104.502 47.5949L92.3754 24.5852C90.8696 21.805 88.7241 18.3893 85.8457 18.3893V17.9524H107.167V18.3893C103.649 18.3893 102.93 20.7326 104.729 24.4396L110.179 35.6267L112.551 30.331C113.799 27.8452 114.576 25.1526 114.843 22.3875C114.843 19.9647 113.764 18.3363 110.685 18.3363V17.8994H124.371V18.3363C121.066 18.3363 118.774 20.256 116.908 23.1024C115.443 25.531 114.147 28.0568 113.031 30.662L104.862 48.3098C102.996 52.2816 99.1183 60 91.949 60C88.6442 60 87.6447 58.4246 87.6447 56.7167Z" fill="#1D1D1B" />
                        <path d="M120.919 47.1712C124.797 47.1712 126.663 45.2516 126.663 40.9753V18.9585H120.706V17.9524H126.663V15.5296C126.663 6.97707 133.406 0.635498 145.319 0.635498C155.58 0.635498 160.377 5.26921 160.377 9.39984C160.387 10.1718 160.243 10.938 159.955 11.6549C159.667 12.3717 159.239 13.0251 158.696 13.5777C158.154 14.1303 157.507 14.5713 156.793 14.8755C156.078 15.1798 155.311 15.3413 154.534 15.3509C153.757 15.3604 152.986 15.2178 152.264 14.9313C151.543 14.6447 150.885 14.2197 150.329 13.6807C149.772 13.1416 149.329 12.4989 149.022 11.7894C148.716 11.0799 148.554 10.3174 148.544 9.54547C148.534 8.31815 148.9 7.11685 149.592 6.10038C150.285 5.0839 151.271 4.30012 152.422 3.85262C151.647 2.93054 150.667 2.1996 149.559 1.7172C148.452 1.2348 147.247 1.01408 146.039 1.07239C140.948 1.07239 138.043 4.56754 138.043 9.26745V17.9524H153.541L162.216 17.1712V41.1871C162.216 44.7485 162.656 47.1712 166.521 47.1712V47.6611H146.585V47.2374C150.45 47.2374 150.876 44.8147 150.876 41.2533V24.9426C150.876 21.5137 150.45 18.9585 146.878 18.9585H138.056V41.0415C138.056 46.3372 141.001 47.2374 144.879 47.2374V47.6611H120.893L120.919 47.1712Z" fill="#1D1D1B" />
                        <path d="M165.814 47.1712C169.692 47.1712 170.119 44.7485 170.119 41.1871V25.0088C170.119 21.4475 169.692 19.0247 165.814 19.0247V18.6011L181.233 17.2109V23.0494C184.164 19.9912 188.255 17.2109 193.133 17.2109C198.01 17.2109 202.168 19.9912 202.168 26.9815V41.1871C202.168 44.7485 202.607 47.1712 206.472 47.1712V47.6611H187.123V47.2374C190.134 47.2374 190.707 44.8146 190.707 41.2533V25.6575C190.707 22.1624 189.122 20.7326 186.896 20.7326C184.854 20.879 182.946 21.7983 181.566 23.301V41.1871C181.566 44.7485 182.139 47.1712 185.15 47.1712V47.6611H165.814V47.1712Z" fill="#1D1D1B" />
                        <path d="M229.073 43.4643V21.3019C228.731 20.3551 228.124 19.5249 227.324 18.9082C226.524 18.2916 225.564 17.9141 224.555 17.8199C219.678 17.8199 218.599 27.1536 218.599 32.489C218.599 39.9691 220.758 45.3839 224.982 45.3839C225.759 45.3528 226.521 45.1668 227.224 44.837C227.927 44.5072 228.555 44.0404 229.073 43.4643ZM206.699 32.8729C206.699 24.3998 211.923 17.1315 221.97 17.1315C224.439 17.0784 226.881 17.643 229.073 18.7732V7.8376C229.073 3.49515 227.207 1.85349 223.329 1.85349V1.42983L240.48 0V41.1871C240.48 44.7485 240.906 47.1712 244.784 47.1712V47.6611H229.433V43.8217C228.269 45.2596 226.793 46.4173 225.116 47.2083C223.438 47.9993 221.602 48.403 219.745 48.3892C211.429 48.3892 206.699 40.9753 206.699 32.9126" fill="#1D1D1B" />
                        <path d="M256.618 30.8473H266.292C266.292 27.9347 266.292 17.5287 261.775 17.5287C257.777 17.5287 256.618 25.4722 256.618 30.8473ZM244.917 32.8464C244.917 24.2277 251.58 17.1712 261.988 17.1712C271.17 17.1712 277.046 22.7317 277.406 31.4298H256.618C256.684 40.8296 260.336 46.6019 266.865 46.6019C271.529 46.6019 274.754 43.6099 276.913 41.0415L277.273 41.3327C275.519 43.6283 273.232 45.4687 270.608 46.6974C267.984 47.926 265.1 48.5064 262.201 48.3892C250.794 48.3892 244.877 40.9753 244.877 32.8464" fill="#1D1D1B" />
                        <path d="M277.979 47.1713C281.857 47.1713 282.283 44.5234 282.283 40.9753V25.3001C282.283 21.7388 281.857 19.0909 277.979 19.0909V18.6673L293.33 17.211V27.3919C294.663 22.1889 297.915 17.1315 303.018 17.1315C303.832 17.1058 304.643 17.2461 305.4 17.5437C306.157 17.8413 306.844 18.2899 307.42 18.8619C307.996 19.4338 308.447 20.1169 308.747 20.8691C309.046 21.6212 309.188 22.4263 309.162 23.2348C309.19 24.03 309.054 24.8225 308.76 25.5628C308.467 26.3031 308.023 26.9753 307.456 27.5374C306.889 28.0994 306.211 28.5393 305.466 28.8294C304.72 29.1196 303.922 29.2538 303.122 29.2237C302.321 29.1935 301.536 28.9997 300.814 28.6543C300.093 28.309 299.451 27.8194 298.928 27.2164C298.406 26.6133 298.014 25.9097 297.778 25.1494C297.542 24.3891 297.466 23.5886 297.555 22.7979C295.729 23.849 294.351 25.5243 293.677 27.5111V41.0415C293.677 45.0133 294.823 47.2375 298.701 47.2375V47.6611H277.979V47.1713Z" fill="#1D1D1B" />
                        <path d="M310.041 47.0256L309.748 37.5463L310.041 37.4801C312.706 42.9611 314.919 47.9523 321.448 47.9523C326.459 47.9523 327.325 45.0397 327.325 43.3186C327.325 35.3751 309.748 38.6981 309.748 26.7961C309.748 20.4545 314.705 17.1712 322.741 17.1712C327.178 17.1712 330.19 18.1641 333.921 17.3168L334.281 27.5772L334.001 27.6434C331.629 22.3477 328.764 17.5949 323.34 17.5949C319.343 17.5949 318.25 20.0971 318.25 22.0167C318.25 29.4307 335.96 26.3592 335.96 38.2612C335.96 45.0397 330.23 48.3892 321.901 48.3892C317.903 48.2911 313.922 47.8482 310.001 47.0653" fill="#1D1D1B" />
                    </g>
                </svg>
            </div>
        </div>

        <div className={css.content}>
            <div className={css.contentBody}>
                <div className={css.heroText}>
                    We build and invest <br />
                    in ambitious companies that change the way the world works.
                </div>
                <div className={css.line} />
                <div className={css.buttomText}>
                    Technology, human capability, market expertise and limitless ambition...
                </div>
            </div>
        </div>

    </>
}

export default Hero