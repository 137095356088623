// extracted by mini-css-extract-plugin
export var graySession = "style-module--gray-session--ZVmb9";
export var highlightedText = "style-module--highlighted-text--S6XnL";
export var joinEmailInput = "style-module--join-email-input--95-g5";
export var joinInputsContainer = "style-module--join-inputs-container--GdjMW";
export var joinSection = "style-module--join-section--qOIT3";
export var joinSectionSmallText = "style-module--join-section-small-text--9KHp6";
export var joinSectionText = "style-module--join-section-text--SXwY-";
export var joinSubmitButton = "style-module--join-submit-button--JwxvZ";
export var section = "style-module--section--aSrtf";
export var sectionText = "style-module--section-text--ocMNH";
export var sectionTitle = "style-module--section-title--167Ii";
export var sections = "style-module--sections--j1ISg";
export var yourMindTitle = "style-module--your-mind-title--8gH1v";